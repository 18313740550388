import Swal from 'sweetalert2';

const initUsersDelete = function() {
  $(document).on('click.del-user').on('click.del-user', "a.del-user", function(){
    let id = $(this).data('id')
    let user = $(this).data('user')
    Swal.fire({
      title: 'Are you sure?',
      text: `User ${user} will be irreversibly deleted!`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        $.post(`/users/${id}`, {
          "_method": "delete",
          id: id
        }).then((r) => {
          window.location.reload(true);
        }).fail((xhr) => {
          Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const initClinicUserAssign = function() {
  $(document).off('click.assign-user')
    .on('click.assign-user', "a.assign-user", function(){
      let clinicUserId = $(this).data('id')
      $.getScript(`/users/${clinicUserId}/assign`)
    })
}

const onClinicUserAssignSave = function() {
  $(document).off('click.save-assign-user')
    .on('click.save-assign-user', "button.save-assign-user", function(){
      let clinicUserId = $(this).data('id')
      let assignedClinicians = $("select#all_users_ids").val();
      $.post(`/users/${clinicUserId}/assign`, {
        ids: assignedClinicians,
        id: clinicUserId
      }).done(function(){
        $("div#assign-modal").modal('hide')
        window.location.reload(true)
      })
  })
}

export default {
  onClinicUserAssignSave,
  init() {
    initUsersDelete()
    initClinicUserAssign()
    onClinicUserAssignSave()
  }
}