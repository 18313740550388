import Swal from 'sweetalert2';

const initFileDelete = function() {
  $("a.del-file.btn-tbl-delete").off('click.df').on('click.df', function(e){
    e.preventDefault()
    let id = $(this).data('id')
    let file = $(this).data('file')
    Swal.fire({
      title: 'Are you sure?',
      text: `File '${file}' will be irreversibly deleted!`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        $.post(`/system_files/${id}`, {
          "_method": "delete",
          id: id
        }).then((r) => {
          window.location.hash = '#files';
          window.location.reload(true);
        }).fail((xhr) => {
          Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

export default {
  init() {
    initFileDelete()
  }
}