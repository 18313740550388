import Vue from 'vue'
import * as SurveyVue from 'survey-vue'
import Swal from 'sweetalert2';
import { jquerybarrating } from 'jquery-bar-rating/jquery.barrating'
import 'jquery-bar-rating/dist/themes/css-stars.css'
import * as widgets from "surveyjs-widgets";

widgets.inputmask(SurveyVue);
widgets.jquerybarrating(SurveyVue);
const storageName = "QstStorage";

const initQuestionnaireDelete = function() {
  $(document).on('click.del-qs').on('click.del-qs', "a.del-qs", function(){
    let id = $(this).data('id')
    let title = $(this).data('title')
    Swal.fire({
      title: 'Are you sure?',
      text: `Questionnaire '${title}' will be irreversibly deleted!`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        $.post(`/patient_forms/${id}`, {
          "_method": "delete",
          id: id
        }).then((r) => {
          window.location.reload(true);
        }).fail((xhr) => {
          Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const removeUserResults = function() {
  $(document).off('click.remove-res').on('click.remove-res', "a.remove-result", function(e){
    e.preventDefault()
    let requestUrl = $(this).data('url')
    let id  = $(this).data('id')
    Swal.fire({
      title: 'Are you sure?',
      text: `All below results from this questionnaire will be deleted?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
    if (result.value) {
      $.post(requestUrl, {
        "_method": "delete",
        id: id
      }).then((r) => {
        window.location.reload(true);
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const removeSingleResult = function() {
  $(document).off('click.duqf').on('click.duqf', "a.del-uqf", function(e){
    e.preventDefault()
    let id  = $(this).data('id')
    Swal.fire({
      title: 'Remove finding?',
      text: `It won't be possible to restore this item?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then((result) => {
    if (result.value) {
      $.post(`/user_questionnaire_findings/${id}/destroy_single`, {
        "_method": "delete",
        id: id
      }).then((r) => {
        window.location.reload(true);
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const saveState = function(survey) {
  var res = {
    currentPageNo: survey.currentPageNo,
    data: survey.data
  };
    //Here should be the code to save the data into your database
  window
    .localStorage
    .setItem(storageName, JSON.stringify(res));
}

const loadState = function(survey) {
  var storageSt = window.localStorage
      .getItem(storageName) || "";
  var res = {};
  if (storageSt) res = JSON.parse(storageSt); 
  
  if (res.currentPageNo) 
      survey.currentPageNo = res.currentPageNo;
  if (res.data) 
      survey.data = res.data;
}

const saveStateinDatabase = function(survey) {
  let pathRes = window.location.pathname.split("/")
  let id = pathRes[pathRes.length-1];
  if (id === 'shared') id = pathRes[2]
  $.post('/patient_forms/save_results', { result_json: survey.data, id: id })
}

const loadStateFromDatabase = function(questionnaireId, questionnaireResultId, survey) {
  $.getJSON(`/patient_forms/${questionnaireId}/form_results/${questionnaireResultId}.json`).then((responseJson) => {
    survey.currentPageNo = 1
    survey.data = responseJson
  })
}
const initShareQuestionnaire = function() {
  $(document).off('click.share-qs')
    .on('click.share-qs', "a.share-qs", function(){
      let qsId = $(this).data('id')
      $.getScript(`/questionnaires/${qsId}/share`)
  })
}

const onQuestionnaireSharingClick = function() {
  $(document).off('click.share-qs-save')
    .on('click.share-qs-save', "button.share-this-qs", function(){
      let qsId = $(this).data('id')
      let assignedUsers = $("select#all_users_ids").val();
      $.post(`/questionnaires/${qsId}/share_qs`, {
        ids: assignedUsers,
        id: qsId
      }).done(function(){
        $("div#assign-modal").modal('hide')
        window.location.reload(true)
      })
  })
}

export default {
  saveState,
  loadState, 
  saveStateinDatabase,
  loadStateFromDatabase,
  onQuestionnaireSharingClick,
  removeUserResults,
  removeSingleResult,
  initShareQuestionnaire,
  init(json, displayMode) {
    var Survey = SurveyVue.Survey
    Survey.cssType = "modern";
    window.survey = new SurveyVue.Model(json);
    
    window.survey.showProgressBar = 'top';
    
    window.survey.onCurrentPageChanged.add(function (survey, options) {
      saveState(survey)
    });

    loadState(survey);
  
    var timerId = window.setInterval(function () {
      saveState(window.survey);
    }, 10000);

    survey.onComplete.add(function (survey, options) {
      clearInterval(timerId);
      saveStateinDatabase(survey);
    });

    if (displayMode) {
      survey.mode = 'display';
    }

    new Vue({ el: '#surveyContainer', data: { survey: window.survey } });
  },

  initQuestionnaireDelete() {
    return initQuestionnaireDelete()
  }
}