import "core-js/stable";
import "regenerator-runtime/runtime";
require('popper.js')

import jQuery from 'jquery'

const jQueryProp = {
  value: jQuery,
  configurable: false,
  writable: false
}

Object.defineProperties(window, { jQuery: jQueryProp, $: jQueryProp })
require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-bs4')(window, $)
require('imports-loader?define=>false!datatables.net-responsive' )( window, $ );
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
require('./app')
require('./layout')
require('./theme-color')
require('select2')
require('jquery-bar-rating/dist/jquery.barrating.min')
require('pretty-checkbox')
require('survey-vue')
require('inputmask')
require('surveyjs-widgets')
require("expose-loader?Questionnaire!./questionnaire.js");
require('fullcalendar')
require('fullcalendar/dist/gcal')
require("expose-loader?CalendarModule!./calendar.js");
require("expose-loader?SettingsModule!./settings.js");
require("expose-loader?BookingsModule!./bookings.js");
require("expose-loader?Globals!./global.js");
require("expose-loader?Tree!./tree.js");
require('expose-loader?UI!./ui.js')
require('expose-loader?GlossaryModule!./glossary.js')
require('expose-loader?BloodResultsModule!./blood_results.js')
require('expose-loader?ImagingModule!./imaging.js')
require('expose-loader?ExertionTestModule!./exertion_tests.js')
require('expose-loader?BodyCompositionModule!./body_composition.js')
require('expose-loader?ScreeningResultsModule!./screening_results.js')

require('summernote/dist/summernote-lite')
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/css/all";

import UI from './ui'
import Users from './users'
import QuestionnaireSetup from './questionnaire'

$(function(){
  UI.init()
  Tree.default.initTree('div#category-treeview')
  Users.init()
  window.Questionnaire = Questionnaire.default
  window.Calendar = CalendarModule.default
  QuestionnaireSetup.initQuestionnaireDelete()
  window.Settings = SettingsModule.default
  window.Bookings = BookingsModule.default
  window.Glossary = GlossaryModule.default
  window.BloodResults = BloodResultsModule.default
  window.Imaging = ImagingModule.default
  window.ExertionTest = ExertionTestModule.default
  window.BodyComposition = BodyCompositionModule.default
  window.ScreeningResults  = ScreeningResultsModule.default
  Bookings.init()
})
