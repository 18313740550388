import Swal from 'sweetalert2';
import Calendar from './calendar'

const initBookingDelete = function() {
  $("a.del-booking.btn-tbl-delete").off('click.db').on('click.db', function(e){
    e.preventDefault()
    let id = $(this).data('id')
    Swal.fire({
      title: 'Are you sure?',
      text: `This booking will be removed along with corresponding calendar entry`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        $.post(`/bookings/${id}`, {
          "_method": "delete",
          id: id
        }).then((r) => {
          window.location.reload(true);
        }).fail((xhr) => {
          Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const onResetClick = function() {
  $('a.btn-reset').on('click', function(){
    var filterDate = $("input#from_date_booking").val('')
    $("a.box-refresh").click()
    window.location.href=`/bookings`
  })
  $("input#from_date_booking").on('change', function(){
    var filterDate = $("input#from_date_booking").val()
    if (filterDate) {
      $("a.box-refresh").click()
      window.location.href=`/bookings?start_date=${filterDate}`
    } else {
      Swal.fire("Date is missing", "Please select date first!", "error")
    }
  })
}

const downloadPdfBookingList = function() {
  $(document).off('click.fld').on('click.fld', 'a.btn-download-clinic-list', function(){
    var filterDate = $("input#from_date_booking").val()
    if (filterDate) {
      $("a.box-refresh").click()
      window.location.href=`/bookings.pdf?start_date=${filterDate}`
    } else {
      Swal.fire("Date is missing", "Please select date first!", "error")
    }
  })
}

const sendInstituteEmail = function() {
  $(document).off('click.in-eml').on('click.in-eml', 'a.btn-institute-email', function(){
    var filterDate = $("input#from_date_booking").val().replace(/\//g, '-')
    if (filterDate) {
      Swal.fire({
        title: 'Would you like to send this list?',
        text: `An email with a currently displayed list will be sent to Manchester Institute of Health and Performance`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send it please!'
      }).then((result) => {
        if (result.value) {
          $("a.box-refresh").click()
          $.get(`/bookings/${filterDate}/institute_email`, {
            id: filterDate
          }).then((r) => {
            window.location.reload(true)
          }).fail((xhr) => {
            Swal.fire('Error!', xhr.responseText, 'error')
          })
        }
      })

    } else {
      Swal.fire("Date is missing", "Please select date first!", "error")
    }
  })
}

export default {
  init() {
    initBookingDelete()
    Calendar.bookingCancellationPrompt()
    onResetClick()
    downloadPdfBookingList()
    sendInstituteEmail()
  }
}