import Swal from 'sweetalert2'

const saveExertionTest = function() {
  $(document).off('click.save-et').on('click.save-et', 'button#save-exertion-test', function(){
    let form = $('form#new-exertion-test-form')
    $.post(`/exertion_tests`, form.serialize())
  })
}

const editUserExertionTest = function() {
  $(document).off('click.edit-uet').on('click.edit-uet',"button#edit-exertion-test", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    $.getScript(`/exertion_tests/${id}/edit.js`)
  })
}

const loadDescriptions = function(userId, date) {
  let loadingUrl = `/exertion_tests/${userId}/descriptions/${date}`
  $.getScript(loadingUrl)
}

const updateExertionTest = function() {
  $(document).off('click.update-et').on('click.update-et',"button#update-exertion-test", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    var data = $('form#edit-exertion-test-form').serialize()
    data = data + "&_method=put" 
    $.post(`/exertion_tests/${id}.js`, data)
  })
}

const loadExertionTestTree = function(selector, userId, group, date = null) {
  let urlToLoad = null
  let tree = "exertionTestTree"
  let blankMessage = `<div class="alert alert-info">Currently there is no data here. Click above to add</div>`
  if (date === null) {
    urlToLoad = `/exertion_tests.json?user_id=${userId}&group=${group}`
  } else {
    tree = "exertionTestReportTree"
    urlToLoad =`/exertion_tests.json?user_id=${userId}&date=${date}`
    blankMessage =  `<div class="alert alert-info">Currently there is no data for this date. Select other</div>`
  }
  $.getJSON(urlToLoad, function(json){
    if (json.length > 0) {
      window[tree] = $(selector).fancytree({
        icon: function(event, data) {
          if( data.node.isFolder() ) {
            return "fa fa-folder text-blueish";
          } else {
            return 'fa fa-file-alt text-blueish'
          }
        },
        glyph: {
          preset: "awesome4",
          map: {}
        },
        activate: function(event, data){
          var node = data.node;
          let id = node.data.id
          if (id === undefined) return
          if (!data.node.folder && data.node.data) {
            let showUrl = `/exertion_tests/${id}.js`
            if (date) showUrl = showUrl+"?report=true"
            $.getScript(showUrl)
          }
        
        },
        selectMode: 3,
        extensions: ['edit', 'glyph'],
        source: json
      });
      if (date && window[tree]) {
        $.getJSON(urlToLoad, function(json) {
          window[tree].fancytree('getTree').reload(json);
        })
      }
    } else {
      $(selector).html(blankMessage);
    }
  })
}


const deleteExertionTest = function() {
  $(document).off('click.del-ex-test').on('click.del-ex-test', 'button#delete-exertion-test', function(){
    let id = $(this).data('id')
    Swal.fire({
      title: 'Remove this exertion test?',
      text: `This cannot be reversed`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then((result) => {
    if (result.value) {
      $.post(`/exertion_tests/${id}`, {
        "_method" : "delete",
        "id" : id
      }).then((r) => {
        window.location.reload(true);
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

export default {
  saveExertionTest,
  loadExertionTestTree,
  loadDescriptions,
  init() {
    saveExertionTest()
    deleteExertionTest()
    editUserExertionTest()
    updateExertionTest()
  }
}