import moment from 'moment'
import Swal from 'sweetalert2'
require('webpack-jquery-ui/interactions');

const EVENTS_COLORS = {
  other: "#1ebdcc",
  confirmed: '#18ce0f',
  bookable: '#dd4b39'
}

const isConfirmedEvent = function(description)  {
  let confirmedString = "\n==============\nConfirmed";
  return description.match(confirmedString)
}

const isBookableEvent = function(description) {
  return description.match("Phone:") && description.match("Email:") && description.match("Name:")
}

const parsedContent = function(dStart, eventId, description) {
  if (description === undefined || description === null) return ''
  if (isConfirmedEvent(description)) return confirmedEventLinks(dStart, eventId, description)
  if (isBookableEvent(description)) {
    return `<a class='btn btn-info btn-xs booking-confirm' id='${eventId}'><i class='fa fa-calendar-check'></i>&nbsp;Confirm this booking</a><br><br>${dStart}:<pre>${(description) || ''}</pre>`
  } else {
    return `<a class='btn btn-circle btn-danger del-event' id='ev-${eventId}' data-id='${eventId}'> <i class='fa fa-trash-alt'></i>&nbsp;Delete</a>`
  }
}

const confirmedEventLinks = function(dStart, eventId, description) {
  return `<div class='row ml-2 mb-2'><a class='btn btn-circle btn-success'> <i class='fa fa-calendar-check'></i>&nbsp;Confrimed</a>&nbsp;<a class='btn btn-circle btn-danger cancel-booking' id='cancel-${eventId}' data-id='${eventId}'> <i class='fa fa-ban'></i>&nbsp;Cancel</a><br><br></div>${dStart}:<pre>${(description) || ''}</pre>`
}

const dismissOnClickAway = function() {
  $('#calendar, .card').on('click', function(e) {
    if (!$(e.target).hasClass('popover')) {
      $('[data-original-title]').popover('hide');
    }
  })
}

const onEventDeleteClick = function() {
  $(document).off('click.del-ev').on('click.del-ev', 'a.del-event', function(){
    var eventId = $(this).attr("id").replace('ev-','')
    Swal.fire({
      title: 'Remove this event?',
      text: 'Would you like to remove this event from calendar?',
      showCloseButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        $.post(`/events/${eventId}`, {"_method": "delete"}, function(){
          window.location.reload(true)
        })
      }
    })
  })
}

const loadEvents = function() {
  $.ajax('/events.json', { 
    type: "GET",    
    dataType: "json"
  }).done(function(json){
    json.forEach(cevent => {
    let ev = {
      id: cevent.id,
      start: moment(cevent.start.date_time).toDate(),
      end: moment(cevent.end.date_time).toDate(),
      title: cevent.summary,
      color: eventColor(cevent.description),
      description: cevent.description
    }	
    window.fullCalendar.fullCalendar('renderEvent',ev, true);
    });
  })
}  

const bookingCancellationPrompt = function() {
  $(document).off('click.bcan').on('click.bcan', 'a.cancel-booking', function(){
    var eventId = $(this).attr("id").replace("cancel-","")
    let cancelUrl = `/bookings/${eventId}/cancel`
    Swal.fire({
      title: 'Cancel booking?',
      text: 'Would you like to cancel this booking? The correspoinding customer created with this booking will NOT be deleted.',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        $(".popover").hide()
        //let event = window.fullCalendar.fullCalendar('clientEvents', eventId)[0];
        $.get(cancelUrl, function(res){
          //event.color = '#dd4b39'
          /*window.fullCalendar.fullCalendar('updateEvent', event)
          window.fullCalendar.fullCalendar('refetchEvents')
          window.fullCalendar.fullCalendar('rerenderEvents')
          */
         window.location.reload(true)
        })
      }
    })
  })
}

const addEvent = function() {
  $("#event_add").unbind("click").on('click', function(){
    var e = $("#event_title").val();
    appendEventToList(e)
  })
}

const appendEventToList = function(e) {
  e = 0 === e.length ? "Untitled Event" : e;
  let template = '<div class="external-event label label-event-' +e+'">' + e + "</div>"
  var t = $(template);
  jQuery("#event_box").append(t), makeDraggale(t)
};

const makeDraggale = function(e) {
  var t = {
    title: $.trim(e.text())
  };
  e.data("eventObject", t), e.draggable({
    zIndex: 999,
    revert: !0,
    revertDuration: 0
  })
}

const bookingConfirmationPrompt = function() {
  $(document).off('click.bc').on('click.bc', 'a.booking-confirm', function(){
    var eventId = $(this).attr("id")
    Swal.fire({
      title: 'Confirm booking?',
      text: 'Would you like to open booking confirmation window?',
      showCloseButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        window.location.href=`/bookings/new?event_id=${eventId}`
      }
    })
  })
}

const finalizeBooking = function() {
  $(document).off('click.bf').on('click.bf', 'a#finalize-booking', function(){
    var eventId = $(this).data("id")
    window.location.href=`/bookings/${eventId}/finalize`
  })
}

const eventColor = function(description) {
  if (description === undefined || !isBookableEvent(description)) return EVENTS_COLORS["other"];
  if (isConfirmedEvent(description)) return EVENTS_COLORS["confirmed"];
  return EVENTS_COLORS["bookable"];
}

const updateEvent = function(info) {
  let cevent = {
    start: info.start.toISOString(), end: info.end.toISOString()
  }
  let data = {
    event: cevent, id: info.id, "_method": "put"
  }
  $.post(`/events/${info.id}.json`, data)
} 

export default {
  bookingCancellationPrompt() {
    return bookingCancellationPrompt()
  },
  init() {
    if (jQuery().fullCalendar) {
      window.fullCalendar = $('#calendar').fullCalendar({
        header    : {
          left  : 'prev,next today',
          center: 'title',
          right : 'month,agendaWeek,agendaDay, listMonth'
        },
        buttonText: {
          today: 'Today',
          month: 'Month',
          week : 'Week',
          day  : 'Day',
          listMonth: "Full month list"
        },
        editable  : true,
        droppable : true,
        resizable: true,
        selectable: true,
        eventColor: '#dd4b39',
        drop: function(e, t) {
          var eventObject = $(this).data("eventObject"),
          cevent = $.extend({}, eventObject);
          cevent.start = e._d;
          cevent.color = eventColor(cevent.title)
          cevent.className = $(this).attr("data-class");
          cevent.end =  moment(cevent.start).add(2, 'hours')._d
          $("#drop-remove").is(":checked") && $(this).remove()
          $.post('/events.json', {event: cevent}, function(response){
            let idEvent = $.extend(cevent, response);
            window.fullCalendar.fullCalendar('renderEvent', idEvent)
            $("input#event_title").val('')
          })
        },
        eventResize: function(info) {
          updateEvent(info)
        },
        eventDrop: function(info) {
          updateEvent(info)
        },
        eventRender: function(event, element){
          $(element).attr("role", "button")
          $(element).attr("tabindex", 0)
          $(element).attr("data-toggle", "popover")

          var dStart = event.start.format("DD MMMM");
          element.popover({
            animation: true,
            placement: 'right',
            html: true,
            title: (event.title || ""),
            content: parsedContent(dStart, event.id, event.description),
            trigger: 'focus'
          }).click(function(evt) {
            evt.stopPropagation();
            $(this).popover('show');
          });          
        },
        select: function(start, end, allDay) {
          //newEventPrompt(start, end, allDay)
        }
      })
      loadEvents()
      bookingConfirmationPrompt()
      finalizeBooking()
      dismissOnClickAway()
      bookingCancellationPrompt()
      addEvent()
      onEventDeleteClick()
    }
  }
}