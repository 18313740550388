const $ = require('jquery');
import Swal from 'sweetalert2'
const fancytree = require('jquery.fancytree');
import 'jquery.fancytree/dist/skin-bootstrap/ui.fancytree.css'
require('jquery.fancytree/dist/modules/jquery.fancytree.edit');
require('jquery.fancytree/dist/modules/jquery.fancytree.filter');
require('jquery.fancytree/dist/modules/jquery.fancytree.glyph');


const initQuestionnaireFindingDelete = function() {
  $(document).on('click.del-qf').on('click.del-qf', "a.del-qf", function(){
    let id = $(this).data('id')
    let deleteUrl = $(this).data('url')
    Swal.fire({
      title: 'Are you sure?',
      text: `This item will be irreversibly deleted!`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        $.post(deleteUrl, {
          "_method": "delete",
          id: id
        }).then((r) => {
          Tree.default.reloadFindingsTree()
          $("div.modal").modal("hide")
        }).fail((xhr) => {
          Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

export default {
  init() {
    initQuestionnaireFindingDelete()
  },
  getSelectedData() {
    var selectedItems =$('.occurs-when:checkbox:checked');   
     var allItems = {};
     $.each(selectedItems, function(index, selected){
       var question = $(selected).data("question").toLowerCase();
       var value = $(selected).val()
       if (allItems[question] === undefined) allItems[question] = []  
       allItems[question][index] = value
     });
   return allItems   
 },
 initTree(selector, nodes) {
    $.getJSON('/questionnaire_findings.json', function(json) {
      window.findingsTree = $(selector).fancytree({
        icon: function(event, data) {
          if( data.node.isFolder() ) {
            return "fa fa-folder text-blueish";
          } else {
            return 'fa fa-file-alt text-blueish'
          }
        },
        glyph: {
          preset: "awesome4",
          map: {}
        },
        activate: function(event, data){
          var node = data.node;
          let id = node.data.id
          if (id === undefined) return
          $.get(`/questionnaire_findings/${id}/modal.js`, function(htmlResponse){
            $("div#modal-container").html(htmlResponse);
            $(`div.qs-finding-modal[data-id='${id}']`).modal("show")
          })
        },
        selectMode: 3,
        extensions: ['edit', 'glyph'],
        source: json
      });
    })
  },
  initReportSummaryTree(selector, nodes) {
      window.reportSummaryTree = $(selector).fancytree({
        renderNode: function(event, data) {
          var node = data.node;
          if (node.data) {
              var $span = $(node.span);
              $span.find("span.fancytree-title").text(node.title).css({
                  "white-space": "normal",
                  "margin": "0 30px 0 5px"
              });
          }
      },
        glyph: {
          preset: "awesome4",
          map: {}
        },
        activate: function(event, data){
          var node = data.node;
          let id = node.data.id
          if (id === undefined) return
         
        },
        loadChildren: function() {
          let bloodSummaryText = $($("textarea#user_results_report_blood_results_summary").summernote('code')).text()
          let vascularImagingText = $($("textarea#user_results_report_vascular_imaging_summary").summernote('code')).text()
          var exertionTestText = $($("textarea#user_results_report_exertion_test_summary").summernote('code')).text()

          if (bloodSummaryText !== '') {
            $(selector).fancytree('getTree').getNodeByKey("2").setTitle(bloodSummaryText)
          }
          if (vascularImagingText !== '') {
            $(selector).fancytree('getTree').getNodeByKey("3").setTitle(vascularImagingText)
          }
          if (exertionTestText !== '') {
            $(selector).fancytree('getTree').getNodeByKey("4").setTitle(exertionTestText)
          }
        },
        selectMode: 3,
        extensions: ['edit', 'glyph'],
        source: nodes
      });
  },
  initRadiologyTree(selector, nodes, report = false) {
    let currrentTree = $(selector).fancytree({
      renderNode: function(event, data) {
        var node = data.node;
        if (node.data) {
            var $span = $(node.span);
            $span.find("span.fancytree-title").text(node.title).css({
                "white-space": "normal",
                "margin": "0 30px 0 5px"
            });
        }
      },
      glyph: {
        preset: "awesome4",
        map: {}
      },
      selectMode: 3,
      extensions: ['edit', 'glyph'],
      source: nodes
    });
    report ? window.radiologyReportTree = currrentTree : window.radiologyTree = currrentTree;
    if (!report) {
      currrentTree.on('fancytreeactivate', function(event, data){
        var node = data.node;
        let id = node.data.id
        if (id === undefined) return
        $.getScript(`/user_vascular_imaging_results/${id}`)
      })
    }
  },
  updateTreeNodeText(tree, key, text) {
    if (tree && text !== '') {
      tree.fancytree('getTree').getNodeByKey(key).setTitle(text)
    }
  },
  reloadFindingsTree() {
    $.getJSON('/questionnaire_findings.json', function(json) {
      window.findingsTree.fancytree('getTree').reload(json);
    })
  }
}