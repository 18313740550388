import Swal from 'sweetalert2'
const editBodyComposition = function() {
  $('a.edit-body-composition').on('click', function(){
    let bodyCompositionId = $(this).data('id')
    let originalText = $("div.composition-text").text()
    let saveBCButton = `<a class='btn-xs btn btn-circle blue-bgcolor btn-outline m-b-10 save-body-composition' data-id='${bodyCompositionId}'>
      <i class='fa fa-save'></i>
    </a><a class='btn-xs btn btn-circle red btn-outline m-b-10 cancel-composition' data-id='${bodyCompositionId}'>
      <i class='fa fa-ban'></i>
    </a>`
    $('div.composition-text').html(`<textarea id="bc-data" style='width:100%;height:150px; resize: none'>${originalText}</textarea>`)
    if ($('a.save-body-composition').length === 0) $('p#body-composition-info').prepend(saveBCButton)
    cancelBodyComposition(originalText)
  })
}

const updateBodyComposition = function() {
  $(document).off('click.upd-bc').on('click.upd-bc', 'a.save-body-composition', function(){
    var itemId = $(this).data('id')
    let value = $('textarea#bc-data').val()
    if (value) {
      $.post(`/body_compositions/${itemId}`, {
        "_method" : "put",
        "id" : itemId,
        "report_text": value
      })
    } else {
      Swal.fire('Error', 'Body composition report text summary cannot be blank', 'error')
    }   
  })
}

const cancelBodyComposition = function(originalText) {
  $(document).off('click.can-bc').on('click.can-bc', 'a.cancel-composition', function(){
    $('a.save-body-composition, a.cancel-composition, textarea#bc-data').remove()
    $("div.composition-text").text(originalText)
  })
}

export default {

  init() {
    editBodyComposition()
    updateBodyComposition()
  }
}