import Swal from 'sweetalert2'

const saveUserImaging = function() {
  $(document).off('click.suimg').on('click.suimg',"button#save-user-v-imaging", function(e){
    e.preventDefault()
    let userId= $(this).data('user-id')
    let data = $('form.new_user_vascular_imaging_result').serialize()
    $.post(`/user_vascular_imaging_results?user_id=${userId}`, data)
  })
}

const initForm = function() {
  UI.default.initSelect2WithImages('select#user_vascular_imaging_result_vascular_imaging_category_id')
  UI.default.initDatePickerWithoutTime('input#user_vascular_imaging_result_date_taken')
  UI.default.initSmallSummernote("textarea#user_vascular_imaging_result_description", 200)
  $("div#new-v-imaging-result").on('shown.bs.modal', function(){
    $("div#v-img-error-container").html('')
  })
}

const deleteImagingItem = function() {
  $(document).off('click.del-dvimg').on('click.dvimg', "a.delete-vimg", function(e){
    e.preventDefault()
    let id  = $(this).data('id')
    Swal.fire({
      title: 'Remove vascular imaging?',
      text: `This action cannot be reverted`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then((result) => {
    if (result.value) {
      $.post(`/user_vascular_imaging_results/${id}`, {
        "_method": "delete",
        id: id
      }).then((r) => {
        window.location.reload(true)
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const editUserImaging = function() {
  $(document).off('click.evimg').on('click.evimg',"a.edit-vimg", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    $.getScript(`/user_vascular_imaging_results/${id}/edit`)
  })
}

const editUserImagingSave = function() {
  $(document).off('click.euimgs').on('click.euimgs',"button#update-user-v-imaging", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    let data = $('form.edit_user_vascular_imaging_result').serialize()
    $.post(`/user_vascular_imaging_results/${id}`, data)
  })
}

export default {
  initForm,
  init() {
    initForm()
    saveUserImaging()
    deleteImagingItem()
    editUserImaging()
    editUserImagingSave()
  }
}