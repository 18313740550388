
import Swal from 'sweetalert2'

const shareScreeningResultsReport = function() {
  $(document).off('click.share-rep').on('click.share-rep', 'a.share-report', function(){
    let reportId = $(this).data('id')
    Swal.fire({
      title: 'Would you like to share this report?',
      text: "An email with a downloadable link to the pdf report (not an attachment) will be send to the customer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, share it please'
    }).then((result) => {
      if (result.value) {
        $.getScript(`/screening_results_sharings/new?id=${reportId}`)
      }
    })
  })
}

const onScreeningResultsReportDelete = function() {
  $(document).off('click.del-scrr').on('click.del-scrr', "a.delete-report", function(e){
    e.preventDefault()
    let id  = $(this).data('id')
    Swal.fire({
      title: 'Remove generated report?',
      text: `If you remove this report customer status will change back to screened and enable option to generate a new one`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then((result) => {
    if (result.value) {
      $.post(`/user_results_reports/${id}`, {
        "_method": "delete",
        id: id
      }).then((r) => {
        window.location.reload(true);
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}
export default {
  init() {
    shareScreeningResultsReport()
    onScreeningResultsReportDelete()
  }
}