

import Swal from 'sweetalert2'


const removeUploadedFile = function() {
  $(document).off('click.dbr').on('click.dbr', "a.delete-br", function(e){
    e.preventDefault()
    let id  = $(this).data('id')
    Swal.fire({
      title: 'Remove uploaded blood result file?',
      text: `All corresponding blood results records extracted from this document will be deleted as well`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then((result) => {
    if (result.value) {
      $.post(`/blood_results/${id}`, {
        "_method": "delete",
        id: id
      }).then((r) => {
        window.location.reload(true);
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const showBloodResultsDetails = function() {
  $(document).off('click.sbr').on('click.sbr',"a.view-br", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    let attachmentId = $(this).data('attachment-id')
    $.getScript(`/blood_results/${id}.js?attachment_id=${attachmentId}`)
  })
}

const deleteUserBloodResult = function() {
  $(document).off('click.del-ubr').on('click.del-ubr', "a.delete-user-br", function(e){
    e.preventDefault()
    let id  = $(this).data('id')
    Swal.fire({
      title: 'Remove item from results?',
      text: `Only selected item will be removed from file findings`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then((result) => {
    if (result.value) {
      $.post(`/blood_results/${id}/destroy_single`, {
        "_method": "delete",
        id: id
      }).then((r) => {
        $(`table tr[data-id=${id}]`).remove()
      }).fail((xhr) => {
        Swal.fire('Error!', xhr.responseText, 'error')
        })
      }
    })
  })
}

const editUserBloodResult = function() {
  $(document).off('click.edit-ubr').on('click.edit-ubr',"a.edit-user-br", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    $.getScript(`/blood_results/${id}/edit.js`)
  })
}

const updateUserBloodResult = function() {
  $(document).off('click.update-ubr').on('click.update-ubr',"button#save-user-blood-result", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    var data = $('form.edit_user_blood_result').serialize()
    data = data + "&_method=put" 
    $.post(`/blood_results/${id}`, data)
  })
}

const addNewUserBloodResult = function() {
  $(document).off('click.anubr').on('click.anubr',"a.add-blood-result-entry", function(e){
    e.preventDefault()
    let categoryId = $(this).data('blood-result-category-id')
    let attachmentId = $(this).data('attachment-id')
    let userId = $(this).data('user-id')
    $.getScript(`/blood_results/new_single.js?category_id=${categoryId}&attachment_id=${attachmentId}&user_id=${userId}`)
  })
}

const onBloodResultSelectChange = function() {
  $(document).off('change.bld-rs').on('change.bld-rs', 'select#user_blood_result_blood_result_id', function(){
    let id = $(this).val()
    let userId = $(this).data('user-id')
    $.getJSON(`/blood_results/show_single?id=${id}.json`, function(jsonResponse){
      $("input#user_blood_result_range").val(jsonResponse.range)
      $("input#user_blood_result_blood_result_category_id").val(jsonResponse.blood_result_category_id)
    })
  })
}

const saveNewUserBloodResult = function() {
  $(document).off('click.save-ubr').on('click.save-ubr',"button#save-new-user-blood-result", function(e){
    e.preventDefault()
    var data = $('form.new_user_blood_result').serialize()
    $.post(`/blood_results/create_single.js`, data)
  })
}

export default {
  removeUploadedFile,
  init() {
    removeUploadedFile()
    showBloodResultsDetails()
    deleteUserBloodResult()
    editUserBloodResult()
    updateUserBloodResult()
    addNewUserBloodResult()
    onBloodResultSelectChange()
    saveNewUserBloodResult()
  }
}