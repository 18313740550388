import Swal from 'sweetalert2'

const addImagesPicture = function (opt) {
	if (!opt.id) {
		return opt.text;
	}               
	var optimage = $(opt.element).data('image'); 
	if(!optimage){
		return opt.text;
	} else {
		var $opt = $(
		'<span class="v-img"><img src="' + optimage + '" class="img-pic" width="33" height="33" />&nbsp; ' + $(opt.element).text() + '</span>'
		);
		return $opt;
	}
}

export default {
  init() {
    $("table.dataTable").DataTable({
      responsive: true,
      stateSave: true
    })
    this.initSelect2()
    this.initMultiSelect()
    this.serializeFormAsObject()
    this.goToTab()
    $("[data-toggle='tooltip']").tooltip()
  }, 
  errorMessage(title, message) {
    Swal.fire(title, message, 'error')
  },
  serializeFormAsObject() {
    $.fn.serializeObject = function() {
      var o = {};
      var a = this.serializeArray();
      $.each(a, function() {
        if (o[this.name]) {
          if (!o[this.name].push) {
            o[this.name] = [o[this.name]];
          }
          o[this.name].push(this.value || '');
        } else {
          o[this.name] = this.value || '';
        }
      });
      return o;
    };
  },
  initSelect2() {
    $("select.select2").select2({
      theme: "bootstrap"
    });
  },
  initMultiSelect() {
    $("select.select2-multiple").select2({
      theme: "bootstrap", 
      tags: true, 
      allowClear: true,
      placeholder: "Please select"
    });   
  },
  initSelect2WithImages() {
    $("select.select2-images").select2({
      theme: "bootstrap", 
      templateResult: addImagesPicture,
      templateSelection: addImagesPicture,
      allowClear: true,
      placeholder: "Please select"
    });   
  },
  initQuestionnaireFindingForms() {
    $("select#questionnaire_finding_questionnaire_id").on("change", function(){
      let id = $(this).val()
      $.get(`/questionnaire_findings/${id}/questions.js`, function(){
        UI.default.initMultiSelect()
      })
    });
    UI.default.initSmallSummernote('#questionnaire_finding_description')
    $(document).off('click.save-finding').on('click.save-finding', "button#save-finding", function(){
      let form = $("form#new_questionnaire_finding")
      let data = form.serializeObject()
      let requestUrl = form.attr("action")
      data['occurs_when'] = Tree.default.getSelectedData()
      $("div#error-container").html('')
      $.post(requestUrl, data)
    })    
  },
  bindQuestionnaireFindingCategoryForm() {
    $(document).off('click.save-finding-cat').on('click.save-finding-cat', "button#save-qf-category", function(){
      let form = $("form#new_questionnaire_finding_category")
      let data = form.serializeObject()
      let requestUrl = form.attr("action")
      $.post(requestUrl, data)
    })
  },
  bindUpdateQuestionnaireFindingFormAction() {
    $(document).off('click.update-finding').on('click.update-finding', "button#update-finding", function(){
      let form = $("form#edit_questionnaire_finding")
      let data = form.serializeObject()
      let requestUrl = form.attr("action")
      data['occurs_when'] = Tree.default.getSelectedData()
      data["_method"] = "put"
      $("div#error-container").html('')
      $.post(requestUrl, data)
    })    
  },
  highlightElement(element){
    $(element).addClass('highlight').delay(600).queue(function(next){
      $(this).removeClass('highlight');
      next();
    })
  },
  goToTab() {
    var hash = document.location.hash;
    var prefix = "";
    if (hash) {
        $('a.nav-link[href="' + hash.replace(prefix, "") + '"], .nav-pills a[href="' + hash.replace(prefix, "") + '"]').tab('show');
    }

    $('a.nav-link, .nav-pills a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash.replace("#", "#" + prefix);
        if (window.location.query !== undefined) {
            $("a[data-parent-tab]#" + window.location.query).click();
            window.location.query = undefined;
        }
    });
  },
  initDatePickerWithoutTime(selector) {
    $(selector).bootstrapMaterialDatePicker({
      format: 'DD/MM/YYYY',
      lang: 'en',
      time: false,
      weekStart: 1, 
      cancelText : 'Cancel',
      nowButton : true,
      switchOnClick : true
    })
  },
  initSmallSummernote(selector, height = 300, callbacks) {
    $.summernote.dom.emptyPara = "<div><br></div>"
    $(selector).summernote({
      theme: 'boostrap4',
      height: height,
      maxHeight: 350,
      disableResizeEditor: true,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['fontname', 'strikethrough']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        [ 'para', ['ul', 'ol', 'paragraph']]
      ],
      callbacks: callbacks
    });
  }
}