export default {
  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + ' target=\'_blank\'>' + url + '</a>';
    })
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  },
  printContent(el) {
    var restorepage = $('body').html();
    var printcontent = $('#' + el).clone();
    $('body').empty().html(printcontent);
    window.print();
    $('body').html(restorepage);
  }, 
  calculateBMI(weight, height) {
    return (weight/((height/100) * (height/100))).toFixed(2)
  }, 
  calculateWaistHipRatio(waist, hip) {
    return (waist/hip).toFixed(2);
  },
  removeUrlParameter(url, parameter) {
    var urlParts = url.split('?');
  
    if (urlParts.length >= 2) {
      var urlBase = urlParts.shift();
      var queryString = urlParts.join('?');
      var prefix = encodeURIComponent(parameter) + '=';
      var parts = queryString.split(/[&;]/g);
      for (var i = parts.length; i-- > 0; ) {
        if (parts[i].lastIndexOf(prefix, 0) !== -1) {
          parts.splice(i, 1);
        }
      }
  
      url = urlBase + '?' + parts.join('&');
    }
    return url;
  },
  getIdFromPath() {
    var array = window.location.pathname.split("/")
    return array[array.length-1]
  }
}