import Swal from 'sweetalert2'

const onGlossaryModalTrigger = function() {
  $(document).off('click.gt').on('click.gt', "a.glossary-trigger", function(e){
    e.preventDefault()
    let id = $(this).data('id')
    $.getScript(`/glossary_items/${id}/edit`, function(){
      onGlossaryEditFormSubmit(id)
    })
  })
}

const onGlossaryNewFormSubmit = function(id) {
  $(document).on("shown.bs.modal", "div#new-glossary-modal", function(){
    $(document).off('click.sg').on('click.sg', "button#save-glossary", function(){
      let formData = $("form.glossary-new-form").serialize()
      $.post(`/glossary_items`, formData)
    })
  })
}

const onGlossaryEditFormSubmit = function(id) {
  $("div#glossary-modal").on("shown.bs.modal", function(){
    $("button.update-glossary").off('click.ug').on('click.ug', function(){
      let formData = $("form.glossary-edit-form").serialize()
      $.post(`/glossary_items/${id}`, formData +`&_method=put&id=${id}'`)
    })
  })
}

const onGlossaryItemDelete = function() {
  $("a.del-glossary-item").on('click', function(){
    let id = $(this).data('id')
    Swal.fire({
      title: "Remove item?",
      text: `Glossary item will be deleted?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Remove'
    }).then(result => {
      if (result.value) {
        $.post(`/glossary_items/${id}`, `&_method=delete&id=${id}'`,function(){
          window.location.reload(true)
        })
      }
    })
  })
}

export default {
  onGlossaryNewFormSubmit,
  init() {
    onGlossaryModalTrigger()
    onGlossaryItemDelete()
    onGlossaryNewFormSubmit()
  }
}