require('jquery-ui/ui/widget');

require('jquery-ui/ui/data');

require('jquery-ui/ui/disable-selection');

require('jquery-ui/ui/scroll-parent');

require('jquery-ui/ui/widgets/draggable');

require('jquery-ui/ui/widgets/droppable');

require('jquery-ui/ui/widgets/resizable');

require('jquery-ui/ui/widgets/selectable');

require('jquery-ui/ui/widgets/sortable');

require('jquery-ui/ui/widgets/mouse');

require('jquery-ui/themes/base/core.css');

require('jquery-ui/themes/base/draggable.css');

require('jquery-ui/themes/base/resizable.css');

require('jquery-ui/themes/base/selectable.css');

require('jquery-ui/themes/base/sortable.css');